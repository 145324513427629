exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-classes-tsx": () => import("./../../../src/pages/Classes.tsx" /* webpackChunkName: "component---src-pages-classes-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-employment-tsx": () => import("./../../../src/pages/Employment.tsx" /* webpackChunkName: "component---src-pages-employment-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/Links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/Services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

